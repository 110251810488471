<template>
  <main class="container">
    <div class="header h3 mt-2">
          Listado de Contratos
        <!-- <button class="btn-2 float-end" @click="gotoDiverseProducts()"><i class="fas fa-plus me-2"/> Crear Nuevo</button> -->
    </div>

    <!-- ADD NEW MODAL -->
    <b-modal id="modal-1" title="Agregar Región" class="p-3" centered v-model="showNewModal" hide-footer>
        <form @submit.prevent="postData()" class="mx-3">
            <label class="text-green-2">Código:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newItem.id">
            
            <label class="text-green-2">Nombre:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newItem.name">
            
            <label class="text-green-2">NIT:</label>
            <input type="text" class="form-control border-secondary mb-2" required v-model="newItem.nit">
            
            <label class="text-green-2">Email:</label>
            <input type="text" class="form-control border-secondary mb-4" required v-model="newItem.email">

            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showNewModal=false">Cencelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <div class="row mt-2 mx-1 " 
         style="min-height:75vh; background:rgba(0,0,0,0.5);">
        
        <div class="col">            
            <b-table
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(creationDate)="data">
                    <span>{{$moment(data.item.creationDate).format('DD-MM-YYYY')}}</span>
                </template>
                 <template #cell(acciones)="data">
                    <div>                        
                        <button @click="$router.push('contractDetail/'+data.item.id)" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-file-alt"></i></button>
                    </div>
                </template>

            </b-table>

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[],
        fields: [
          { key: "id", label:"Contrato"},
          { key: "warehouse", label:"Almacén"},
          { key: "creationDate", label:"Fecha Salida"},
          { key: "total", label:"Total"},
        //   { key: "sendername", label:"Emisor"},
          { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
        ],
        perPage: 10,
        currentPage: 1,

        newItem:{},
        showNewModal:false,
        selectedItem:{},
        showEditModal:false,
        table: 'transfers',
    }
},
async mounted(){
    await this.getData()
},
methods:{
    gotoDiverseProducts(){
        this.$router.push('contractDetail')
        // this.showEditModal=true;
        // this.selectedItem=data.item
    },
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getAllByType/4'});
            //console.log(res)
            if (res.length >0){
                this.items = res
            }
        } catch (error) {
            this.items = []
            console.error('error', error)
        }
    },       
    async postData(){
        try {
            let res = await this.$store.dispatch('post', {path: this.table + '/new' , data: this.newItem});
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.getData()
                this.showNewModal=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        } catch (error) {
            this.items = []
            console.error('error--->', error)
        }        
    },
    async putData(){
        try {
            let data = Object.assign({} , this.selectedItem) 
            delete(data.id)
            let res = await this.$store.dispatch('put', {path: this.table + '/modify/'+ this.selectedItem.id , data: data});
            if (res.error){
                this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
            }
            else{
                this.showEditModal=false
                this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
            }
        } catch (error) {
            this.items = []
            console.error('error--->', error)
        }
    },       
    async deleteItem(item){
        const {isConfirmed} = await this.$swal({
            title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
            icon:"question",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            reverseButtons: true

        })
        if(isConfirmed){
            try {
                let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.getData()
                    this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                }
            } catch (error) {
                this.items = []
                console.error('error--->', error)
            }                    
        }
    }
}
}
</script>

<style>

</style>